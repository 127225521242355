<template>
    <div>
        <div class="listing" style="position: relative;" :style="{'height': drawerHeight}">
            <div class="sidebar-slider" v-show="showAssignSlide ? true : false">
                <v-navigation-drawer
                    absolute
                    v-model="showAssignSlide"
                    right
                    hide-overlay
                    :height="drawerHeight"
                    width="50%"
                    class="merchandise-drawer"
                    >
                        <v-app-bar color="#E0F5F5" elevation="0">
                            <div class="title">
                                <span v-if="showAssignSlide === 'edit'">
                                    <hb-icon class="ml-n1">mdi-square-edit-outline</hb-icon> Assign Products ({{ selectedRows.length }} Products)
                                </span>
                                <span v-if="showAssignSlide === 'download'">
                                    <hb-icon class="ml-n1">mdi-square-edit-outline</hb-icon> Download & Upload
                                </span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-row justify="end">
                                <hb-btn icon tooltip="Close" @click="hideSlideEvent" active-state-off>
                                mdi-close
                                </hb-btn>
                            </v-row>
                        </v-app-bar>
                        <div class="main-content-box white" style="height: 80%;">
                            <div class="description-box hb-text-night-light">
                                Select the required products to add them to the property product catalog
                            </div>
                            <hb-form v-if="showAssignSlide === 'edit'" label="Assign Products to Properties" full :style="{'height': `calc(${drawerHeight} - 175px)`}" class="hb-corporate-properties download-corp-report">

                                <hb-combobox
                                    v-model="itemsSelected"
                                    :items="propertyItems"
                                    id="primary_properties"
                                    name="primary_properties"
                                    label="Select Properties"
                                    data-vv-scope="default"
                                    data-vv-name="primary_properties"
                                    data-vv-as="Properties"
                                    :error="errors.has('default.primary_properties')"
                                    select
                                    item-value="id"
                                    item-text="name"
                                    return-object
                                >
                                </hb-combobox>
                            </hb-form>

                            <div v-if="showAssignSlide === 'download'">
                                <hb-form label="Download Report" full>
                                    <hb-btn @click="downloadReport">Download</hb-btn>
                                    <p class="hb-text-light mt-3">Inventory Report.csv</p>
                                </hb-form>
    
    
                                <hb-form label="Download Template" full>
                                    <hb-btn color="secondary">Download</hb-btn>
                                    <p class="hb-text-light mt-3">New_Merchandise Report.csv</p>
                                </hb-form>
    
                                <hb-form label="Upload Template" class="download-corp-report" full last :style="{'height': `calc(${drawerHeight} - 290px)`}">
                                    <div>
                                        <p class="hb-text-light mb-6"><strong>Note:</strong> Make sure that the spreadsheet you are uploading is complete. If the spreadsheet passed validation, the information will automatically be pushed to Hummingbird and any further changes will need to be made inside Hummingbird.</p>
                                        <HbFileInput class="my-4"placeholder="Select Document"/>
                                        <hb-btn color="secondary">Upload</hb-btn>
                                    </div>
                                </hb-form>
                            </div>

                        </div>
                        <v-app-bar color="white" v-if="showAssignSlide === 'edit'" class="footer-actions" absolute bottom elevation="0">
                            <v-spacer></v-spacer>
                            <div>
                                <hb-link class="mr-2" @click="hideSlideEvent">Cancel</hb-link>
                                <hb-btn color="primary" :disabled="isAssigning" :loading="isAssigning" @click="assignPropertiesEvent">Save</hb-btn>
                            </div>
                        </v-app-bar>
                </v-navigation-drawer>
            </div>
            <hb-data-table 
                v-if="isLoadingData || corporate_merchandises.length" 
                :headers="tableColumns"
                :loading="isLoadingData" 
                :items="corporate_merchandises"
                :show-select="showAssignSlide === 'edit' ? true : false"
                v-model="selectedRows"
                fixed-header
                :search="search"
                class="corporate-merchand-table"
                :class="{'frozen-column-table' : showAssignSlide !== 'edit' && !isLoadingData}"
                @click:row="viewProductDetails"
                >
                <template v-slot:item.data-table-select="{ isSelected, select }">
                    <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>

                <template v-slot:item.sku="{ item }">
                    <hb-tooltip v-if="item.sku && item.sku.length > 10">
                        <template v-slot:item>
                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                {{ trimTextEvent({ value: item.sku, limit: 10 }) }}
                            </span>
                        </template>
                        <template v-slot:body>
                            {{ item.sku }}
                        </template>
                    </hb-tooltip>
                    <span v-else>{{ item.sku }}</span>
                </template>

                <template v-slot:item.name="{ item }">
                    <hb-tooltip v-if="item.name && item.name.length > 33">
                        <template v-slot:item>
                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                {{ trimTextEvent({ value: item.name, limit: 33 }) }}
                            </span>
                        </template>
                        <template v-slot:body>
                            {{ item.name }}
                        </template>
                    </hb-tooltip>
                    <span v-else>{{ item.name }}</span>
                </template>

                <template v-slot:item.default_price="{ item }">
                    <span>${{ item.default_price }}</span>
                </template>

                <template v-slot:item.default_cost="{ item }">
                    <span>${{ item.default_cost }}</span>
                </template>
                
                <template v-slot:item.total_cost_product="{ item }">
                    <span>${{ item.total_cost_product }}</span>
                </template>
                
                <!-- <template v-slot:item.description="{ item }">
                    <hb-tooltip max-width="500px">
                        <template v-slot:item>
                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                {{ trimTextEvent({ value: item.description, limit: 25 }) }}
                            </span>
                        </template>
                        <template v-slot:body>
                            {{ item.description }}
                        </template>
                    </hb-tooltip>
                </template> -->
                <template v-slot:item.no_of_properties="{ item }">
                    <div class="d-flex flex-column" v-if="item.no_of_properties && item.no_of_properties.length">
                        <hb-tooltip>
                            <template v-slot:item>
                                    <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                        {{ item.no_of_properties.length }} {{ item.no_of_properties.length <= 1 ? 'Property' : 'Properties' }}
                                    </span>
                            </template>
                            <template v-slot:body>
                                <div v-for="p in item.no_of_properties" :key="p.id">
                                    <span v-if="p">
                                        {{ p.property_name }}
                                    </span>
                                </div>
                            </template>
                        </hb-tooltip>
                    </div>
                </template>

                <!-- <template v-slot:item.buy_back="{ item }">
                    <span v-if="item.buy_back">
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                    </span>
                    <span v-else>
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                    </span>
                </template> -->

                <template v-slot:item.archived="{ item }">
                    <span v-if="item.archived">
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                    </span>
                    <span v-else>
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu options class="d-flex justify-center">
                        <v-list>
                            <v-list-item @click="editProductEvent(item)">
                                <v-list-item-title>Edit Product</v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item @click="showDeleteDialogEvent(item)">
                                <v-list-item-title>Remove Product</v-list-item-title>
                            </v-list-item> -->
                            <v-list-item @click="archiveProductEvent(item)">
                                <v-list-item-title>{{ item.archived ? 'Activate' : 'Deactivate' }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>

                <template v-slot:item.last_inventory_update="{ item }">
                    <div class="text-center">
                        <hb-tooltip v-if="item.last_inventory_update">
                             <template v-slot:item>
                                 <hb-icon class="pr-2">
                                    mdi-account-clock-outline
                                 </hb-icon>
                             </template>
                             <template v-slot:body>
                                 <p class="mb-0">{{ item.last_inventory_update | formatLocalDateTimeServices }}</p>
                                 <p class="mb-0">By: {{ item.updated_by }}</p>
                             </template>
                         </hb-tooltip>
                    </div>
                </template>
                <template
                    v-if="isLoadingData || corporate_merchandises.length" 
                    v-slot:footer
                >
                    <div class="pa-3 pl-5 cm-footer">
                        Total Inventory Cost: <strong>${{ total }}</strong>
                    </div>
                </template>
            </hb-data-table>
        </div>

        <div class="no-data-box" v-if="!isLoadingData && !corporate_merchandises.length">
            <p class="hb-text-light">Add merchandise to create property inventory.</p>
            <hb-btn @click="addProductDialog = true">Add Merchandise</hb-btn>
        </div>

        <add-product v-model="addProductDialog" :selected="selected" @close="addProductDialog=false" @reset-selected="selected=null" @fetchProducts="fetchProducts" />

        <hb-modal v-model="deleteDialog" size="medium" title="Delete Product" confirmation>
            <template v-slot:content>
                <div class="px-6 py-4">
                    Are you sure you want to delete this product?
                </div>
                <div class="px-6 pb-4">
                    If you do, you will lose all the information regarding this product.
                </div>
            </template> 
            <template v-slot:right-actions>
                <hb-btn color="destructive" :disabled="isDeleting" @click="deleteProductEvent">Delete</hb-btn>
                <span v-show="isDeleting">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

        <hb-modal v-model="productDetailsDialog" size="large" title="View Product" :footerOff="true">
            <template v-slot:subheader>
                <span class="hb-text-light">
                    Changing the inventory threshold at a property level will override corporate settings
                </span>
            </template>
            <template v-slot:content>
                <hb-form label="Name">
                    <p>{{ productData.name }}</p>
                </hb-form>

                <hb-form label="Images" full>
                    <div class="product-images display-flex" v-if="productData.Images && productData.Images.length">
                        <div class="image-item mb-2" v-for="(image, index) in productData.Images" :key="index">
                            <img :src='"data:image/" + image.image_type + ";base64," + image.data_url' alt="" srcset="">
                        </div>
                    </div>
                </hb-form>

                <hb-form label="SKU">
                    <p>{{ productData.sku }}</p>
                </hb-form>

                <hb-form label="Description">
                    <p>{{ productData.description }}</p>
                </hb-form>

                <hb-form label="Default Price" required>
                    <p v-if="productData.price">${{ productData.price }}</p>
                </hb-form>

                <hb-form label="Default Cost" required>
                    <p v-if="productData.default_cost">${{ productData.default_cost }}</p>
                </hb-form>

                <hb-form label="Weight">
                    <p v-if="productData.weight">{{ productData.weight_unit }}: {{ productData.weight }}</p>
                </hb-form>

                <hb-form label="Retail (Taxable)">
                    <p>{{ productData.taxable ? 'Yes' : 'No' }}</p>
                </hb-form>

                <hb-form label="Assigned Properties">
                    <p v-if="productData.Properties">
                        <hb-chip v-for="(item, i) in productData.Properties" :key="'property_chip_' + i">
                            {{ item.property_name }}
                        </hb-chip>
                    </p>
                </hb-form>

                <hb-form label="Inventory Threshold">
                    <p>{{ productData.inventory_threshold }}</p>
                </hb-form>

                <hb-form label="Quantity per Package">
                    <p>{{ productData.quantity_per_package }}</p>
                </hb-form>

                <hb-form label="Negative Inventory"
                    description="Selling a product when its inventory is 0 will create negative inventory and will reflect in accounting.">
                    <hb-checkbox v-model="productData.negative_inventory" label="Allow selling this product past 0 inventory" />
                </hb-form>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import api from "../../../assets/api";
import Loader from "../../assets/CircleSpinner.vue";
import moment from 'moment';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters } from 'vuex';

export default {
    name: "CorporateMerchandise",
    mixins: [ notificationMixin ],
    components: {
        'add-product': () => import('./AddProduct.vue'),
        Loader
    },
    props: {
        showAssignSlide: {
            type: String,
            default: ''
        },
        search: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            tableColumns: [
                { text: "SKU", value: "sku", width: "100px"},
                { text: "Name", value: "name", sortable: false, width: "250px" },
                // { text: "Description", value: "description", sortable: false, width: "300px" },
                { text: "Default Price", value: "default_price", sortable: false, width: "120px" },
                { text: "Default Cost", value: "default_cost", sortable: false, width: "120px" },
                { text: "Notification Threshold", value: "threshold", sortable: false, width: "80px" },
                { text: "Total Inventory", value: "total_inventory", sortable: false, width: "80px" },
                { text: "Total Cost of Product", value: "total_cost_product", sortable: false, width: "120px" },
                { text: "Last Inventory Update", value: "last_inventory_update", sortable: false, width: "130px" },
                { text: "# of Properties", value: "no_of_properties", sortable: false, width: "180px" },
                // { text: "Buy Back", value: "buy_back", sortable: false, width: "100px" },
                { text: "Active", value: "archived", sortable: false },
                { text: "", value: "actions", sortable: false},
            ],
            corporate_merchandises: [],
            isLoadingData: false,
            pagination: {},
            query: '',
            addProductDialog: false,
            selected: null,
            showDrawer: false,
            itemsSelected: [],
            deleteDialog: false,
            deleteItem: null,
            isDeleting: false,
            isAssigning: false,
            selectedRows: [],
            total: 0,
            drawerHeight: '100%',
            tableHeight: '100%',
            productData: {},
            productDetailsDialog: false,
            responseData: []
        }
    },
    created() {
        this.fetchProducts();
    },
    computed: {
        ...mapGetters({
            primaryRoleProperties: 'propertiesStore/primaryRoleProperties',
            primaryRoleId: 'authenticationStore/getPrimaryRoleId'
        }),
        propertyItems() {
            return this.primaryRoleProperties.map(p => {
                return {
                    id: p.id,
                    name: (p.number ? p.number + ' - ' : '') + (p.city ? p.city + ' - ' : '') + p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            })
        }
    },
    watch: {
        deleteDialog: function(newValue) {
            if (!newValue) {
                this.deleteItem = null;
            }
        }
    },
    methods: {
        setDrawerHeight() {
            let documentHeight = document.body.clientHeight;
            let tableElement = document.getElementsByClassName('corporate-merchand-table');
            if (tableElement && tableElement[0]) {
                let tableTop = tableElement[0].getBoundingClientRect().top;
                this.drawerHeight = `${Math.ceil((documentHeight - tableTop) - 11)}px`;
                this.$emit('set-sidebar-height', documentHeight - tableTop);
                this.tableHeight = `${Math.ceil((documentHeight - tableTop) - 78)}px`;
                const innerElement = tableElement[0].querySelector(".v-data-table__wrapper");
                innerElement.style.maxHeight = this.tableHeight;
            }
        },
        async fetchProducts() {
            try {
                this.isLoadingData = true;
                let response = await api.get(this, api.MERCHANDISE);
                let { merchandise, total_summary } = response;
                if (!merchandise?.length) {
                    this.$emit('hide-filters', true);
                } else {
                    this.$emit('hide-filters', false);
                    this.corporate_merchandises = [];
                }
                this.responseData = merchandise;
                this.corporate_merchandises = merchandise ? merchandise.map(item => {
                    let fullName = item.summary_inventory && item.summary_inventory.modified_by_first && item.summary_inventory.modified_by_last ? (item.summary_inventory.modified_by_first + " " + item.summary_inventory.modified_by_last) : '';
                    return {
                        id: item.id,
                        sku: item.sku,
                        product_id: item.product_id,
                        name: item.name,
                        description: item.description,
                        default_price: item.price ? item.price.toFixed(2) : 0,
                        default_cost: item.default_cost ? item.default_cost.toFixed(2) : 0,
                        threshold: item.inventory_threshold ? item.inventory_threshold : 0,
                        total_cost_product: item.summary_inventory.total_default_cost ? item.summary_inventory.total_default_cost.toFixed(2) : 0,
                        total_inventory: item.summary_inventory && item.summary_inventory.total_remaining_inventory ? item.summary_inventory.total_remaining_inventory : 0,
                        last_inventory_update: item.summary_inventory && item.summary_inventory.modified_at && moment(item.summary_inventory.modified_at).isValid() ? item.summary_inventory.modified_at : '',
                        no_of_properties: item.Properties,
                        updated_by: fullName && fullName.trim() ? fullName.trim() : '',
                        archived: item.status ? false : true,
                        buy_back: item?.buy_back ? true : false,

                    }
                }) : [];
                this.total = total_summary && (total_summary.total_cost !== 'null' || total_summary.total_cost !== null) ? Number.parseFloat(total_summary.total_cost).toFixed(2) : 0;
                if (isNaN(this.total)) {
                    this.total = 0;
                }
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingData = false;
                this.setDrawerHeight();
            }
        },
        async editProductEvent(params) {
            try {
                let response = await api.get(this, api.MERCHANDISE + params.id);
                let { merchandise } = response;
                this.selected = Object.assign({}, merchandise ? merchandise : null);
                this.addProductDialog = true;
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        showDeleteDialogEvent(params) {
            this.deleteItem = params;
            this.deleteDialog = true;
        },
        async deleteProductEvent() {
            try {
                this.isDeleting = true;
                await api.delete(this, api.MERCHANDISE + this.deleteItem.id);
                this.deleteDialog = false;
                this.fetchProducts();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isDeleting = false;
            }
        },
        async assignPropertiesEvent() {
            try {
                this.isAssigning = true;
                if(!this.selectedRows.length) {
                    this.showMessageNotification({ description: 'Please select at least one product' });
                    return;
                }
                if(!this.itemsSelected.length) {
                    this.showMessageNotification({ description: 'Please select at least one property' });
                    return;
                }
                let payload = {
                    Products: this.selectedRows.map(item => { return { id: item.product_id }}),
                    Properties: this.itemsSelected.map(item => { return { id: item.id }}),
                }
                await api.post(this, api.MERCHANDISE + 'assign', payload);
                this.hideSlideEvent();
                this.fetchProducts();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isAssigning = false;
            }
        },
        async downloadReport() {
            try {
                let url = api.REPORTS + "download-pdf";
                let timeZone = moment.tz.guess();
                let filters = {
                    "search": {
                        "search": "",
                        "report_date": ""
                    },
                    "columns": [
                        {
                            "key": "merchandise_sku",
                            "width": 150,
                            "label": "SKU",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 1,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_name",
                            "width": 200,
                            "label": "Name",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 2,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchanidse",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_default_price",
                            "width": 70,
                            "label": "Default Price",
                            "input": "text",
                            "options": [],
                            "agg_method": "",
                            "sort": 3,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_default_cost",
                            "width": 70,
                            "label": "Default Cost",
                            "input": "text",
                            "options": [],
                            "agg_method": "",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_total_cost",
                            "width": 70,
                            "label": "Total Cost of Product",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_total_inventory",
                            "width": 70,
                            "label": "Total Inventory",
                            "input": "text",
                            "options": [],
                            "agg_method": "money",
                            "sort": 5,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": true
                        },
                        {
                            "key": "merchandise_total_properties",
                            "width": 70,
                            "label": "# of properties",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 6,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_last_inventory_update",
                            "width": 150,
                            "label": "Last inventory update",
                            "input": "timeframe",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 7,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        }
                    ],
                    "sort": {
                        "field": "product_name",
                        "dir": "ASC"
                    },
                    "pivot_mode": {
                        "type": "",
                        "column": {},
                        "row": {},
                        "pivot_field": {},
                        "agg_method": ""
                    },
                    "groups": [],
                    "roles_id": [this.primaryRoleId]
                }
                let data = {
                    "filters": JSON.stringify(filters),
                    "type": "generic",
                    "report_type": "merchandise_corporate",
                    "format": "web",
                    "name": "Merchandise Corporate",
                    "timeZone": timeZone,
                    "roles_id": [this.primaryRoleId]
                }
                this.showMessageNotification({ type: 'success', description: 'Your download should begin shortly.' });
                let response = await api.post(this, url, data);
                const arr = new Uint8Array(response?.data);
                var blob = new Blob([arr], {type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =  'merchandise-report';
                link.click();
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        trimTextEvent(params = {  value: '', limit: 10}) {
            let { value, limit } = params;
            if (value && value.length > limit)
                return value.substring(0, limit) + '...';
            return value;
        },
        hideSlideEvent() {
            this.selectedRows = [];
            this.itemsSelected = [];
            this.$emit('hide-slide');
        },
        async archiveProductEvent(params) {
            try {
                this.isLoadingData = true;
                let payload = {
                    "archived": params.archived ? false : true
                }
                await api.put(this, api.MERCHANDISE + params.id + '/archive', payload);
                await this.fetchProducts();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingData = false;
            }
        },
        async viewProductDetails(params) {
            let response = await api.get(this, api.MERCHANDISE + params.id);
            let { merchandise } = response;
            this.productData = merchandise;
            this.productDetailsDialog = true;
        }
    },
    destroyed(){
        this.errors.clear();
        this.$validator.reset();
        this.$validator.errors.clear();
    }
}
</script>


<style lang="scss" scoped>
.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.cm-footer {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.merchandise-drawer {
    height: 100%;
    top: 36px;
    transform: translateX(0%);
    z-index: 11;
    width: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.description-box {
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #dfe3e9;
    border-bottom: 1px solid #dfe3e9;
}
.footer-actions {
    border-top: 1px solid #dfe3e9 !important;
}

.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.image-item {
    max-width: 150px;
    padding: 8px;
    background-color: #DFE3E8;
    border-radius: 4px;
    margin-right: 8px;
}

.image-item img {
    width: 100%;
}
</style>

<style lang="scss">
.hb-corporate-properties .row.no-gutters {
    height: 100%;
}

.download-corp-report  .row.no-gutters {
    height: 100%;
}

.frozen-column-table {
  table {
    > thead, > tbody {
      > tr {
        // Styling for the first, second, and last columns
        th:first-child,
        td:first-child,
        th:nth-child(2),
        td:nth-child(2),
        th:last-child,
        td:last-child {
          position: sticky !important;
          position: -webkit-sticky !important;
          z-index: 3 !important;
          background: white;

          &:first-child {
            left: 0 !important;
            max-width: 100px;
          }

          &:nth-child(2) {
            left: 100px !important;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 2px;
              background: rgba(0, 0, 0, 0.1);
              box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
              pointer-events: none;
            }
          }

          &:last-child {
            right: 0 !important;
            width: 45px;
            max-width: 45px;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 2px;
              background: rgba(0, 0, 0, 0.1);
              box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
              pointer-events: none;
            }
          }
        }

        // Adjusting z-index for header cells
        th:first-child,
        th:nth-child(2),
        th:last-child {
          z-index: 4 !important;
        }
      }
    }
  }
}
</style>